import React from 'react';
import { Button } from '@zydalabs/zac-react';

import { UPGRADE_YOUR_FREE_PLAN } from 'constants/translations';
import { Text } from 'components/service';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';

declare global {
  interface Window {
    Intercom: any;
  }
}

type BlockedFeatureProps = {
  featureTitle: Array<string>;
  featureDescription: Array<string>;
  featureListTitle: Array<string>;
  featureList: Array<Array<string>>;
  translate: (translationTextArray: Array<string>) => string;
};

const BlockedFeature: React.FC<BlockedFeatureProps> = ({
  featureTitle = ['', ''],
  featureDescription = ['', ''],
  featureListTitle = ['', ''],
  featureList = [['', '']],
  translate,
}) => (
  <div className="grid grid-cols-2 divide-x p-52">
    <div>
      <div className="flex flex-row">
        <UpgradeRocketIcon />
        <Text
          testId="blocked-feature-call-to-action-text"
          className="font-semibold text-sm pl-2"
          value={featureTitle}
        />
      </div>
      <Text testId="blocked-feature-attraction-title" className="font-bold text-3xl pt-4" value={featureDescription} />
      <div className="mt-10 w-56">
        <Button
          onClick={() => {
            if (window.Intercom) window.Intercom('show');
          }}
          type="button"
          size="small"
          rounded
          text={translate(UPGRADE_YOUR_FREE_PLAN)}
        />
      </div>
    </div>
    <div className="pl-8">
      <Text
        testId="blocked-feature-feature-attributes-title"
        className="font-semibold text-sm pl-2"
        value={featureListTitle}
      />
      <ul className="pl-8 pt-4">
        {featureList.map((featureTextArray, index) => (
          // Disabled here since it's not the main identifor the list item
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${featureTextArray[0]}-${index}`} className="list-disc text-zyda-grey-45 opacity-60">
            <Text testId={`blocked-feature-feature-attributes-${index}`} value={featureTextArray} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default BlockedFeature;
